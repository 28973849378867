// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

// const channels = require.context('.', true, /_channel\.js$/)
// channels.keys().forEach(channels)

// Import all files that match the pattern ./_channel.js using Vite's import.meta.glob
const channels = import.meta.glob('./*_channel.js', { eager: true })

// Alternatively, if you want to search recursively, you can use
// const channels = import.meta.glob('./**/*_channel.js', { eager: true })

for (const path in channels) {
    channels[path]()
}
