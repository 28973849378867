
// Example: Load Rails libraries in Vite.

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "./style.css"
import "./pagy-tailwind.css"

import '@hotwired/turbo-rails'
import { Application } from "@hotwired/stimulus"

import * as Turbo from "@hotwired/turbo"
Turbo.start()

const application = Application.start()
// Dynamically import all Stimulus controllers
const controllers = import.meta.glob("../controllers/*_controller.js")

for (const path in controllers)
{
    controllers[path]().then((module) => {
        const controllerName = path.split("/").pop().replace("_controller.js", "").replace(/_/g, "-")
        application.register(controllerName, module.default)
    })
}

// Dynamically import all channels
import "../channels"

const helpers = import.meta.glob("../helpers/*.js")


// for (const path in channels) {
//     channels[path]().then((module) => {
//         // Assuming your channel modules export a function to connect to the channel
//         if (typeof module.default === 'function') {
//             module.default()
//         }
//     })
// }

import LocalTime from "local-time"
LocalTime.start()

